import { IncomingHttpHeaders, IncomingMessage } from "http";
import { ParsedUrlQuery } from "querystring";

export type Deployment = "ch" | "de";

export const DEFAULT_DEPLOYMENT: Deployment = "ch";

/**
 * To change the deployment for testing, you can use these two methods:
 *
 * 1. Pass a query param: http://localhost:3000?deployment="de"
 *    Limitations:
 *    a) when navigating, the query param will be lost
 *    b) the API routes (/api/feed, /api/images) won't work correctly
 *       as they determine the deployment through the host only. This
 *       means that you won't see images for the "de" deployment.
 * 2. Edit the /etc/hosts file to simulate access through a specific domain like
 *    gemeinwohl.ch or gemeinwohlatlas.de
 */
export function deploymentFromContext({
  query,
  req,
}: {
  query: ParsedUrlQuery;
  req?: IncomingMessage;
}): Deployment {
  if (query && typeof query.deployment === "string" && ["ch", "de"].includes(query.deployment)) {
    return query.deployment as Deployment;
  }

  if (req && deploymentFromHeaders(req.headers)) {
    return deploymentFromHeaders(req.headers);
  }

  return DEFAULT_DEPLOYMENT;
}

export function deploymentFromHeaders(headers: IncomingHttpHeaders): Deployment {
  if (headers.host) {
    if (headers.host.match(/gemeinwohlatlas.de/)) {
      return "de";
    }
    if (headers.host.match(/gemeinwohl.ch/)) {
      return "ch";
    }
  }

  return DEFAULT_DEPLOYMENT;
}

export function foldDeployment<A>(pattern: Record<Deployment, () => A>) {
  return (deployment: Deployment) => pattern[deployment]();
}

export function foldDeployment_<A>(deployment: Deployment, pattern: Record<Deployment, () => A>) {
  return foldDeployment(pattern)(deployment);
}
