import { withProfiler } from "@sentry/react";
import App, { AppContext, AppInitialProps, AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { Deployment, deploymentFromContext, foldDeployment_ } from "../domain/deployment";
import { DEFAULT_LANGUAGE } from "../domain/i18n";

import "leaflet/dist/leaflet.css";

declare const ga: undefined | ((...args: string[]) => void);

interface Props {
  deployment: Deployment;
}

function GemeinwohlAtlasApp(props: AppProps & Props) {
  const { Component, pageProps, deployment } = props;

  React.useEffect(() => {
    const GA = foldDeployment_(deployment, {
      ch: () => process.env.CH_GOOGLE_ANALYTICS_CODE,
      de: () => process.env.DE_GOOGLE_ANALYTICS_CODE,
    });
    if (GA && typeof ga !== "undefined") {
      ga("create", GA, "auto");
      ga("send", "pageview");
    }
  }, [deployment]);

  const { locale = DEFAULT_LANGUAGE } = useRouter();
  const I18nProvider = I18nProviders[deployment][locale] ?? (({ children }) => <>{children}</>);

  return (
    <I18nProvider>
      <Head>
        <meta name="viewport" content="width=1100" />
      </Head>

      <Component {...pageProps} />
    </I18nProvider>
  );
}

const I18nProviders: Record<string, Record<string, React.ComponentType<unknown>>> = {
  ch: {
    de: dynamic(() => import(`../locales/ch/de`)),
    en: dynamic(() => import(`../locales/ch/en`)),
    fr: dynamic(() => import(`../locales/ch/fr`)),
  },
  de: {
    de: dynamic(() => import(`../locales/de/de`)),
    en: dynamic(() => import(`../locales/de/en`)),
  },
};

GemeinwohlAtlasApp.getInitialProps = async (ctx: AppContext): Promise<AppInitialProps & Props> => {
  const deployment = deploymentFromContext(ctx.ctx);

  return {
    ...(await App.getInitialProps(ctx)),
    deployment,
  };
};

export default withProfiler(GemeinwohlAtlasApp);
